import { useState, useEffect } from 'react';
import {
  Box,
  Center,
  Heading,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Stack,
  TabIndicator,
  TabList,
  Tabs,
  Tab,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

function App() {
  console.log('render');
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(true);

  const [tabIndex, setTabIndex] = useState(0);
  const [url, setUrl] = useState(
    'https://airtable.com/embed/shr9NCxTh1yiZAKjK?backgroundColor=purple&viewControls=on'
  );
  const [refresh, setRefresh] = useState(0);

  const menuModal = useDisclosure();
  const newTokenModal = useDisclosure();

  useEffect(() => {
    setLoading(true);
    if (tabIndex === 1) {
      setUrl(
        'https://airtable.com/embed/shrcnRDjQFfmzkvWK?backgroundColor=purple&viewControls=on&_=' +
          new Date().getTime()
      );
    } else {
      setUrl(
        'https://airtable.com/embed/shr9NCxTh1yiZAKjK?backgroundColor=purple&viewControls=on&_=' +
          new Date().getTime()
      );
    }
  }, [tabIndex, refresh]);

  return (
    <Box as="section" h="100vh" display="grid" gridTemplateRows="45px 1fr">
      <Flex p="2" alignItems="center" justifyContent="space-between">
        <Box w="220px">
          <Heading
            as="h1"
            fontSize="2xl"
            bgGradient="linear(to-l, #7928CA,#FF0080)"
            bgClip="text"
            fontWeight="extrabold"
          >
            TokenTracker
          </Heading>
        </Box>
        <Box display={{ base: 'none', md: 'flex' }}>
          <Tabs
            size="sm"
            variant="soft-rounded"
            colorScheme="purple"
            index={tabIndex}
          >
            <TabList>
              <Tab onClick={() => setTabIndex(0)}>Collection</Tab>
              <Tab onClick={() => setTabIndex(1)}>Calendar</Tab>
            </TabList>
          </Tabs>
        </Box>
        <Box>
          <HStack spacing="2" display={{ base: 'none', md: 'flex' }}>
            <Button
              size="sm"
              onClick={() => {
                setRefresh((prev) => prev + 1);
              }}
            >
              Refresh
            </Button>
            <Button
              size="sm"
              colorScheme="purple"
              onClick={() => {
                setLoadingForm(true);
                newTokenModal.onOpen();
              }}
            >
              Submit a token
            </Button>
          </HStack>
          <IconButton
            colorScheme="purple"
            display={{ base: 'block', md: 'none' }}
            aria-label="Menu"
            icon={<HamburgerIcon />}
            onClick={() => menuModal.onOpen()}
          />
        </Box>
      </Flex>
      <Box borderWidth="1px">
        {loading && (
          <Center
            h="100vh"
            position="fixed"
            top="50%"
            left="50%"
            transform="translate(-50%,-50%)"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="purple.500"
              size="xl"
            />
          </Center>
        )}
        <iframe
          id="airtable-embed"
          title="airtable-embed"
          src={url}
          frameBorder="0"
          width="100%"
          height="100%"
          style={{
            background: 'transparent',
          }}
          onLoad={async () => {
            setLoading(false);
          }}
        />
      </Box>
      <Modal
        size="6xl"
        isOpen={newTokenModal.isOpen}
        onClose={() => {
          setRefresh((prev) => prev + 1);
          newTokenModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent h="84vh">
          <ModalHeader>Submit a new token</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="0">
            {loadingForm && (
              <Center
                h="100vh"
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%,-50%)"
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="purple.500"
                  size="xl"
                />
              </Center>
            )}
            <iframe
              id="airtable-form-embed"
              title="airtable-form-embed"
              src="https://airtable.com/embed/shrvS8cQSg3ZNK0IE?backgroundColor=purple"
              frameBorder="0"
              width="100%"
              height="100%"
              style={{
                background: 'transparent',
              }}
              onLoad={async () => {
                setLoadingForm(false);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={menuModal.isOpen} onClose={() => menuModal.onClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Menu</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs
              size="sm"
              variant="soft-rounded"
              colorScheme="purple"
              index={tabIndex}
            >
              <TabList>
                <Tab
                  onClick={() => {
                    setTabIndex(0);
                    menuModal.onClose();
                  }}
                >
                  Collection
                </Tab>
                <Tab
                  onClick={() => {
                    setTabIndex(1);
                    menuModal.onClose();
                  }}
                >
                  Calendar
                </Tab>
              </TabList>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button
                size="sm"
                onClick={() => {
                  setRefresh((prev) => prev + 1);
                  menuModal.onClose();
                }}
              >
                Refresh
              </Button>
              <Button
                size="sm"
                colorScheme="purple"
                onClick={() => {
                  setLoadingForm(true);
                  newTokenModal.onOpen();
                  menuModal.onClose();
                }}
              >
                Submit a token
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default App;
